<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="组织名称" name="organization_name"></c-form-item>
					<c-form-item type="input" label="手机号码" name="check_phone"></c-form-item>
				</c-form>
			</m-search>
			
			<m-operate>
				<c-button icon="add" @click="editHandle()">新建组织架构</c-button>
			</m-operate>
			
			<c-table ref="table">
				<c-table-column
					label="组织名称"
					name="organization_name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="审核人手机号码"
					name="check_phone"
					width="160"
				></c-table-column>
				
				<c-table-column
					label="子账号数量"
					name="user_count"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="创建人"
					name="create_user_name"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					name="create_date_str"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="140"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button @click="editHandle(data)">编辑</c-table-button>
						<c-table-button @click="deleteHandle(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		
		<c-dialog ref="organizeEdit" width="600" button-name="确认并提交" @resolve="editSubmitHandle">
			<organize-edit ref="organizeEditForm"></organize-edit>
		</c-dialog>
	</page>
</template>

<script>
	import organizeEdit from './organize_edit_form.vue'
	
	export default {
		components: {
			organizeEdit
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				this.$refs.table.load({
					url: '/yzdw/organization/list'
				});
			},
			
			editHandle(data) {
				this._edit = !!data;
				if (data) {
					this.$refs.organizeEditForm.edit(data.organization_id);
				} else {
					this.$refs.organizeEditForm.add();
				}
				this.$refs.organizeEdit.open({
					title: data ? '编辑组织架构' : '新建组织架构'
				});
			},
			
			editSubmitHandle(stop, next) {
				stop();
				
				this.$refs.organizeEditForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
					this.$refs.table.update(this._edit ? 0 : 1);
				});
			},
			
			deleteHandle(data) {
				this.$confirm({
					text: '确定要删除此组织吗？',
					resolve: () => {
						this.request({
							url: '/yzdw/organization/delete',
							data: {
								organization_id: data.organization_id
							},
							loading: true,
							success: () => {
								this.$message({
									text: '删除成功'
								});
								this.$refs.table.update();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>