import { render, staticRenderFns } from "./organize_list_pc.vue?vue&type=template&id=5bac89ec&scoped=true"
import script from "./organize_list_pc.vue?vue&type=script&lang=js"
export * from "./organize_list_pc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bac89ec",
  null
  
)

export default component.exports